import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import "@/plugins/vuetify-money";

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,  
  render: h => h(App)
}).$mount('#app')
