<template>
  <v-app>
    <div>
      <v-app-bar color="#F6F6F6" elevation="0" v-if="this.$router.currentRoute.name !== 'login'">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title><a href="/home"><img src="./assets/logo_makode.png" height="40"></a></v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-badge content="6" color="green" overlap offset-x="20" offset-y="23">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-heart</v-icon>

              </v-btn>
            </v-badge>

          </template>

          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

      </v-app-bar>
    </div>
    <v-navigation-drawer v-model="drawer" temporary absolute v-if="this.$router.currentRoute.name !== 'login'">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/88.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Julio Makoto
            </v-list-item-title>
            <v-list-item-subtitle>Administrador</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>


      <v-list nav dense>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modelo padrão</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-animation-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title @click="btn_competencia">Competências</v-list-item-title>
        </v-list-item>
      </v-list>


      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuários</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>      
        <router-view></router-view>      
    </v-main>

    <v-footer v-if="this.$router.currentRoute.name !== 'login'">
      <span class="m_footer">Makode Sistemas LTDA. - Todos os direitos reservados </span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
    items: [
      { title: 'Nova mensagem' },
      { title: 'Conversa da Aninha' },
      { title: 'Recados' },
      { title: 'Mural interno' },
    ]
  }),
  methods: {
    btn_competencia() {
      this.$router.push({ name: 'home' })
    }
  }

};
</script>

<style scope>
.m_footer {
  font-size: 10px;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
</style>
