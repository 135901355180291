<template>
    <v-card class="mx-auto" max-width="500" min-width="200">
        <v-card-text>
            <div>{{ this.textoSuperior }}</div>
            <p class="text-h4 text--primary">
                {{ this.textoTitulo }} <v-icon class="iconChecked">mdi-check-decagram</v-icon>
            </p>
            {{ this.textoSubTitulo }} <br>
            {{this.textoSubTitulo2 }}
        </v-card-text>
        
        <v-card-actions>
            <v-btn text color="blue" @click="reveal = true">
                DETALHES
            </v-btn>
            <v-btn text color="blue" @click="$emit('btn-acao')">
                ENTRAR
            </v-btn>            
        </v-card-actions>

        <v-expand-transition>
            <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                <v-card-text class="pb-0">                    
                    <p class="text--primary">D E T A L H E S</p>
                    <p>{{ this.textoDetalhes }}</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-btn text color="blue" @click="reveal = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    name: 'CardCompetencia',
    emits: ['btn-acao'],
    data: () => ({
        reveal: false,
    }),
    props: {
        textoSuperior: String,
        textoTitulo: String,
        textoSubTitulo: String,
        textoSubTitulo2: String,
        textoBotao: String,
        textoDetalhes: String
    }
}
</script>

<style scoped>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

.iconChecked {
    color: darkgreen;
}

</style>