<template>
    <v-tabs color="teal accent-4" centered>
        <v-tab>Crédito</v-tab>
        <v-tab>Débito</v-tab>
        <v-tab>Resumo</v-tab>

        <!-- Crédito -->
        <v-tab-item>
            <div>
                <section v-if="errored">
                    <p>Não foi possível carregar o conteúdo</p>
                </section>

                <section v-else>
                    <v-card outlined class="cardContas">
                        <div v-if="loading">Carregando...</div>
                        <div class="divPrincipal">
                            <FormConta @refresh="lista_contas('C')" :visible="dialog" @close="dialog = false">
                            </FormConta>
                            <v-row>
                                <v-col cols="12" lg="12" md="12" sm="12" v-for="lC in listaContasCredito"
                                    :key="lC.id + lC.situacao + lC.descricao + lC.valor + lC.debitoAutomatico">
                                    <CardConta :objeto=lC @refresh="lista_contas('C')"></CardConta>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </section>

                <v-btn dark fab color="teal lighten-1" bottom right fixed class="botaoMais" @click.stop="dialog = true">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
        </v-tab-item>

        <!-- Débito -->
        <v-tab-item>
            <div>
                <section v-if="errored">
                    <p>Não foi possível carregar o conteúdo</p>
                </section>

                <section v-else>
                    <v-card outlined class="cardContas">
                        <div v-if="loading">Carregando...</div>
                        <div class="divPrincipal">
                            <FormConta @refresh="lista_contas('D')" :visible="dialog" @close="dialog = false">
                            </FormConta>
                            <v-row>
                                <v-col cols="12" lg="12" md="12" sm="12" v-for="lC in listaContas"
                                    :key="lC.id + lC.situacao + lC.descricao + lC.valor + lC.debitoAutomatico">
                                    <CardConta :objeto=lC @refresh="lista_contas('D')"></CardConta>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </section>

                <v-btn dark fab color="teal lighten-1" bottom right fixed class="botaoMais" @click.stop="dialog = true">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
        </v-tab-item>

        <v-tab-item>
            <v-card outlined class="cardContas">
                <v-row>
                    <v-col>Total de Créditos</v-col>
                    <v-col>
                        {{ this.listaResumo.TotalCreditos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>Total de Débitos</v-col>
                    <v-col>
                        {{ this.listaResumo.TotalDebitos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col></v-col>
                    <v-col>
                        {{ (this.listaResumo.TotalCreditos - this.listaResumo.TotalDebitos).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                    </v-col>
                </v-row>                
            </v-card>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import axios from 'axios'
import CardConta from '@/components/CardConta.vue';
import FormConta from '@/components/FormConta.vue';

export default {
    name: "CompetenciaPage",
    data() {
        return {
            dialog: false,
            listaContas: null,
            listaContasCredito: null,
            listaResumo: null,
            loading: true,
            errored: false                  
        };
    },
    mounted: function () {
        if (sessionStorage.getItem('idCompetencia') == null) {
            this.$router.push("/");
        }

        this.lista_contas('C');
        this.lista_contas('D');
        this.dash_resumo();
    },
    components: { CardConta, FormConta },
    methods: {
        lista_contas(pNatureza) {
            axios.get(sessionStorage.getItem('url') + "contas/1/".concat(sessionStorage.getItem('idCompetencia')).concat('/').concat(pNatureza), {
                data: this.data,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')

                }
            }).then(res => {
                if (pNatureza == 'C') {
                    this.listaContasCredito = res.data;
                } else {
                    this.listaContas = res.data;
                }
            }).catch(err => {
                console.log(err.response);
                this.errored = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        dash_resumo() {
            axios.get(sessionStorage.getItem('url') + "dashboard/1/".concat(sessionStorage.getItem('idCompetencia')), {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                }
            }).then(res => {
                this.listaResumo = res.data[0];        
            }).catch(err => {
                console.log(err.response);
                this.errored = true;
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
.divPrincipal {
    padding-top: 20px;
}

.botaoMais {
    bottom: 60px;
    right: 100px;
}

.cardContas {
    padding: 10px;
    margin: 0 auto;
    max-width: 800px;
}

.resumoRed {
    color: rgb(202, 12, 12);
    font-weight: bold;
}

.resumoGreen {
    color: green;
    font-weight: bold;
}
</style>