import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/pages/HomePage'
import CompetenciaPage from '@/pages/CompetenciaPage'
import LoginPage from '@/pages/LoginPage'
import auth from '@/middleware/auth'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/competencia',
      name: 'competencia',
      component: CompetenciaPage,
      beforeEnter: (to, from, next) => {
        auth(next)
      }
    },
    {
      path: '/home',
      name: 'home',
      component: HomePage,      
      beforeEnter: (to, from, next) => {
        auth(next)
      }      
    },   
  ]
})