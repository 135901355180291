<template>
    <v-container class="fill-height grey lighten-4" fluid>
        <v-row justify="center">
            <v-col align="center">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card flat max-width="450" class="mt-15 rounded-lg">
                        <v-card-title class="justify-center font-weight-light"> Área de Login: </v-card-title>
                        <v-card-text>
                            <v-text-field class="mx-10" outlined
                                prepend-inner-icon="mdi-account-circle-outline teal--text mr-2" label="Usuário"
                                color="teal" v-model="usuario" :counter="150" :rules="usuarioRules" required>
                            </v-text-field>
                            <v-text-field class="mx-10" outlined prepend-inner-icon="mdi-lock-outline teal--text mr-2"
                                label="Senha" :type="showPassword ? 'text' : 'password'"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPassword = !showPassword" color="teal" v-model="senha"
                                :rules="senhaRules" required>
                            </v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn block large class="mx-auto teal lighten-2" :loading="loading" @click="logar">
                                Entrar</v-btn>
                        </v-card-actions>
                        <v-card-actions>
                            <span class="mx-auto my-5">
                                Esqueceu sua senha?
                                <a href="https://www.makode.com.br/" class="mx-auto">
                                    Clique Aqui
                                </a>
                            </span>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.cardLogin {
    border-radius: 35px;
    border: 1px solid;
    background: white;
    padding: 30px;
    margin: 0 auto;
    max-width: 450px;
}
</style>


<script>
import axios from 'axios';

export default {
    name: 'LoginPage',
    data: () => ({
        valid: true,
        loading: false,
        showPassword: false,
        errorMessage: '',
        usuario: '',
        usuarioRules: [
            v => !!v || 'E-mail é obrigatório',
            v => /.+@.+\..+/.test(v) || 'Favor informar um e-mail válido',
        ],
        senha: '',
        senhaRules: [
            v => !!v || 'Senha é obrigatório',
            v => (v && v.length >= 4) || 'A senha deve ter no mínimo 4 caracteres',
        ]
    }),
    mounted: function() {
        sessionStorage.setItem('url', 'https://makode-gestao.herokuapp.com/')      
        //sessionStorage.setItem('url', 'http://192.168.68.121:3000/')      
    },
    methods: {
        logar() {            
            if (this.$refs.form.validate()) {
                this.loading = true

                const param = { empresa: 1, email: this.usuario, senha: this.senha };

                console.log(sessionStorage.getItem('url'));
                axios.post(sessionStorage.getItem('url').concat("usuarios/login"), param)
                    .then(response => {
                        sessionStorage.setItem('TokenAcesso', response.data.token)
                        //console.log(response.data.token)
                        this.loading = false
                        this.$router.push('/home');
                    }).catch(error => {
                        this.errorMessage = error.message;
                        this.errorMessage = "Login ou Senha inválido!!!"
                    });
            }
        }
    }
}
</script>
