import Vue from "vue";
import axios from "axios";
import LoginPage from "@/pages/LoginPage";

Vue.use(auth);

export default async function auth(next) {
  var retorno = false
  retorno = await verificaToken();  
  if (retorno != true) {
        return next({
      path: "/",
      name: "login",
      component: LoginPage,
    });
  }

  return next();
}

async function verificaToken() {  
  var retorno = false
  const config = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("TokenAcesso"),
    },
  };

  await axios
    .get("https://makode-gestao.herokuapp.com/usuarios/verificaToken", config)
    .then(() => {
      console.log("Verificado token OK");      
      retorno = true
    })
    .catch(() => {
      console.log("Verificado token FALHA");      
    });

    return retorno;
}
