<template>

    <section v-if="errored">
        <p>Não foi possível carregar o conteúdo</p>
    </section>

    <section v-else>
        <div v-if="loading">Carregando...</div>

        <v-row>
            <v-col cols="12" lg="2" md="3" sm="6" v-for="lC in listaCompetencias" :key="lC.id">
                <CardCompetencia textoSuperior="COMPETÊNCIA" :textoTitulo=lC.competencia
                    textoSubTitulo="Total de 26 contas" textoSubTitulo2="Resolvidas 18"
                    textoDetalhes="Mostrar detalhes personalizados aqui" textoBotao="Entrar"
                    v-on:btn-acao="btnEntrar(lC)"></CardCompetencia>
            </v-col>
        </v-row>

    </section>

</template>

<script>
import CardCompetencia from "@/components/CardCompetencia.vue";
import axios from 'axios';

export default {
    name: "HomePage",
    components: { CardCompetencia },
    data() {
        return {
            listaCompetencias: null,
            loading: true,
            errored: false
        }
    },
    methods: {
        btnEntrar(pObj) {
            sessionStorage.setItem('idCompetencia', pObj.id)
            this.$router.push({ name: 'competencia' })
        }
    },
    mounted: function () {
        axios.get(sessionStorage.getItem('url').concat('competencias/1'), {
            data: this.data,
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
            }
        }).then(res => {
            this.listaCompetencias = res.data
        }).catch(err => {
            console.log(err.response);
            this.errored = true;
        }).finally(() => this.loading = false);
    }
}

</script>