<template>
  <v-card outlined hover class="card">

    <v-row>
      <div class="tag">
        {{ this.objeto.agrupador_descricao }}
      </div>
      <div class="tag" v-if="this.objeto.debitoAutomatico == 'S'">
        Débito Automático
      </div>
    </v-row>

    <v-row> <!-- :class="[{ rowOK: isCardOK, rowDelayed: isCardDelayed }]"> -->
      <v-col class="d-flex start cardFirstLine" cols="10">
        <b>{{ this.objeto.descricao }}</b>
      </v-col>
      <v-col class="d-flex justify-end cardNotification" cols="2">
        <div :class="[{ textOK: isCardOK, textDelayed: isCardDelayed }]">
          <!--{{ isCardDelayed? 'ATRASADO': isCardOK ? 'PAGO' : '' }} -->
        </div>
        <v-icon color="teal lighten-1" :class="[{ cardIcon: isIcon, cardIconVisible: isCardOK }]">mdi-check-circle
        </v-icon>
        <v-icon color="yellow darken-3" :class="[{ cardIcon: isIcon, cardIconVisible: isCardWarning }]">mdi-alarm
        </v-icon>
        <v-icon color="red darken-3" :class="[{ cardIcon: isIcon, cardIconVisible: isCardDelayed }]">mdi-alert-octagon
        </v-icon>

        <!-- Menu de opções -->
        <v-menu offset-y left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :key="'op1' + objeto.id" @click="menuOpcoesEditar">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small caption>mdi-pencil</v-icon>&nbsp;Editar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :key="'op2' + objeto.id" @click="menuOpcoesApagar">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small>mdi-trash-can-outline</v-icon>&nbsp;Apagar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex start cardThirdLine">
        {{ this.objeto.dataVencimento }}
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex start cardSecondLine ">
        <b>{{ this.objeto.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}</b>
      </v-col>
      <v-col class="d-flex justify-end cardButton">
        <v-btn text small outlined @click="isCardOK ? estornar(objeto.id) : baixaTotal(objeto.id)">
          {{ isCardOK ? 'ESTORNAR' : 'BAIXA TOTAL' }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Dialog excluir -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Exclusão
          </v-card-title>
          <v-card-text>Confirma a exclusão do registro ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogOpcaoExcluir(objeto.id)">
              Sim
            </v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">
              Não
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <FormConta :id="objeto.id" :visible="visible" @close="visible = false" @refresh="refresh">
    </FormConta>

  </v-card>
</template>

<script>
import axios from 'axios';
import FormConta from '@/components/FormConta.vue'

export default {
  name: "CardConta",
  components: { FormConta },
  emits: ["refresh"],
  data() {
    return {
      isIcon: true,
      vDataCorrente: null,
      vDataDepoisAmanha: null,
      vDataBaixa: null,
      vDataVencimento: null,
      isCardDelayed: false,
      isCardOK: false,
      isCardWarning: false,
      dialog: false,
      visible: false
    };
  },
  methods: {
    refresh() {
      this.$emit("refresh")
    },
    baixaTotal(pID) {
      const bodyReq = { id: pID };
      axios.post(sessionStorage.getItem("url").concat("contas/baixarDoc"), bodyReq, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
        }
      }).then(res => {
        console.log(res.data);
      }).catch(err => {
        console.log(err.response);
        this.errored = true;
      }).finally(() => {
        this.loading = false;
        this.$emit("refresh");
      });
    },
    estornar(pID) {
      const bodyReq = { id: pID };
      axios.post(sessionStorage.getItem("url").concat("contas/deixarPendenteDoc"), bodyReq, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso'),
        }
      }).then(res => {
        console.log(res.data);
      }).catch(err => {
        console.log(err.response);
        this.errored = true;
      }).finally(() => {
        this.loading = false;
        this.$emit("refresh");
      });
    },
    menuOpcoesApagar() {
      this.dialog = true
    },
    menuOpcoesEditar() {
      this.visible = true
    },
    dialogOpcaoExcluir(pID) {
      const param = { empresa: 1, competencia: parseInt(sessionStorage.getItem('idCompetencia')), id: pID };
      axios.delete(sessionStorage.getItem("url").concat("contas"), {
        data: param,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
        }
      }).then(res => {
        console.log(res.data);
        this.dialog = false;
        this.$emit("refresh");
      }).catch(err => {
        console.log(err.response);
        this.errored = true;
      });
    },
  },
  mounted: function () {
    this.vDataCorrente = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.vDataDepoisAmanha = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2);
    this.vDataVencimento = new Date(this.objeto.dataVencimento.split("/")[2], this.objeto.dataVencimento.split("/")[1] - 1, this.objeto.dataVencimento.split("/")[0]);
    if ((this.objeto.dataBaixa != undefined) | (this.objeto.dataBaixa != null)) {
      this.vDataBaixa = new Date(this.objeto.dataBaixa.split("/")[2], this.objeto.dataBaixa.split("/")[1] - 1, this.objeto.dataBaixa.split("/")[0]);
    }
    var vBaixaNula = (this.vDataBaixa === undefined | this.vDataBaixa === null) ? 1 : 0;
    this.isCardOK = ((vBaixaNula === 0) ? true : false);
    this.isCardDelayed = ((vBaixaNula) & (this.vDataVencimento < this.vDataCorrente) == 1 ? true : false);
    this.isCardWarning = ((vBaixaNula) & (this.vDataVencimento <= this.vDataDepoisAmanha) & (this.vDataVencimento >= this.vDataCorrente) == 1 ? true : false);
    //this.novaData = new Date().toLocaleDateString()     
  },
  props: {
    objeto: Object
  }
}
</script>

<style scoped>
.tag {
  margin-left: 10px;
  left: 10px;
  top: -4px;
  padding: 5px;
  height: 25px;
  font-size: 10px;
  letter-spacing: 2px;
  color: black;
  background: rgb(239, 239, 239);
  border: 2px solid rgb(239, 239, 239);
  border-radius: 10px;
  position: relative;
}

.card {
  padding-top: 5px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
}

.cardFirstLine {
  padding-bottom: 0;
  letter-spacing: 1px;
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(47, 85, 121);
}

.cardSecondLine {
  padding-top: 0;
  padding-bottom: 0;
  color: rgb(134, 131, 131);
}

.cardThirdLine {
  font-size: 13px;
  padding-top: 0;
  font-size: 14px;
  color: rgb(134, 131, 131);
}

.cardButton {
  padding-top: 0;
  padding-right: 30px;
}

.cardIcon {
  padding-left: 5px;
  display: none;
}

.cardIconVisible {
  display: inline;
}

.rowOK {
  background-color: rgb(244, 248, 244);
}

.rowDelayed {
  background-color: rgb(248, 245, 245);
}

.textDelayed {
  color: rgb(177, 34, 34);
}

.textOK {
  color: rgb(63, 148, 63);
}

.cardNotification {
  padding-right: 40px;
  padding-top: 10px;
  ;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: extra-expanded;
}
</style>


