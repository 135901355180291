<template>
    <v-row>
        <v-dialog v-model="show" persistent max-width="600px">
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title>
                        <span class="text-h5">Nova conta</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="descricao" :counter="255" :rules="descricaoRules"
                                        label="Descrição*" required autofocus clearable outlined>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-radio-group v-model="natureza" row>
                                    <v-radio label="Débito" value="D"></v-radio>
                                    <v-radio label="Crédito" value="C"></v-radio>
                                </v-radio-group>
                            </v-row>
                            <v-row>
                                <v-combobox outlined v-model="v_agrupador" :items="listaAgrupadores"
                                    item-text="agrupador" item-value="id">
                                </v-combobox>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="computedDateFormatted" label="Data de vencimento"
                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                                outlined dense>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="dataVencimento" @input="menu2 = false" locale="pt-br"
                                            no-title>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <vuetify-money v-model="valor" label="Valor" outlined clearable valueWhenIsEmpty="0"
                                        v-bind:options="options" dense />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-checkbox v-model="debitoAutomatico" label="Débito automático" dense>
                                </v-checkbox>
                            </v-row>
                        </v-container>
                        <small>*campos obrigatórios</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="salvarRegistro">
                            Salvar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="show = false">
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<style scoped>
.botaoMais {
    bottom: 50px;
    right: 50px;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'FormConta',
    emits: ['refresh', 'close'],
    data: () => ({
        valid: true,

        descricao: null,
        natureza: 'D',
        v_agrupador: null,
        listaAgrupadores: null,

        valor: 0,
        valueWhenIsEmpty: "",
        options: {
            locale: "pt-BR",
            prefix: "R$",
            suffix: "",
            length: 11,
            precision: 2
        },

        debitoAutomatico: false,

        dataVencimento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,

        // Rules do form
        descricaoRules: [
            v => !!v || 'Descrição é obrigatório',
            v => (v && v.length >= 3) || 'Descrição deve ter no mínimo 3 caracteres',
        ],
    }),

    props: ['visible', 'id'],

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.dataVencimento)
        },
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },

    watch: {
        show(visible) {
            if (visible) {
                // preencher lista de agrupadores
                axios.get(sessionStorage.getItem('url').concat('agrupadores/1'), {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                    }
                }).then(response => {
                    this.listaAgrupadores = response.data
                }).catch(error => {
                    this.errorMessage = error.message;
                    console.log(error.message)
                });

                if (this.id > 0) {                    
                    axios.get(sessionStorage.getItem('url').concat('contas/1/').concat(this.id), {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                        }
                    }).then(response => {
                        this.empresa = response.data[0].empresa;
                        this.competencia = response.data[0].competencia;
                        this.natureza = response.data[0].natureza;
                        this.v_agrupador = JSON.parse(response.data[0].agrupador);                        
                        this.descricao = response.data[0].descricao;
                        this.valor = response.data[0].valor;
                        this.debitoAutomatico = response.data[0].debitoAutomatico == 'S' ? true : false;
                        this.dataVencimento = response.data[0].dataVencimento;
                    }).catch(error => {
                        this.errorMessage = error.message;
                        console.log(error.message)
                    });
                }
            }
        }
    },
    methods: {
        comboChange() {
            console.log(this.v_agrupador)
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        salvarRegistro() {
            if (this.valid) {
                if (this.id > 0) {
                    // Editar 
                    console.log('editar')
                    const param = {
                        natureza: this.natureza, agrupador: this.v_agrupador.id, descricao: this.descricao,
                        dataVencimento: this.dataVencimento, valor: this.valor,
                        debitoAutomatico: this.debitoAutomatico == true ? 'S' : 'N',
                        empresa: 1, competencia: sessionStorage.getItem('idCompetencia'), id: this.id
                    };

                    console.log(param)

                    axios.patch(sessionStorage.getItem('url').concat('contas'), param, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                        }
                    }).then(response => {
                        console.log(response.data)
                        this.$emit('refresh'),
                            this.show = false
                    }).catch(error => {
                        this.errorMessage = error.message;
                        console.log(error)
                    });
                } else {
                    // Novo
                    console.log('novo')
                    const param = {
                        empresa: 1, competencia: sessionStorage.getItem('idCompetencia'),
                        natureza: this.natureza, agrupador: this.v_agrupador.id, descricao: this.descricao,
                        dataVencimento: this.dataVencimento, valor: this.valor,
                        debitoAutomatico: this.debitoAutomatico == true ? 'S' : 'N'
                    };

                    console.log(param)

                    axios.post(sessionStorage.getItem('url').concat('contas'), param, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                        }
                    }).then(response => {
                        console.log(response.data)
                        this.$emit('refresh'),
                            this.show = false
                    }).catch(error => {
                        this.errorMessage = error.message;
                        console.log(error.message)
                    });
                }
            }
        },
    }
}
</script>